<template>
  <div class="d-flex align-center my-3">
    <EventParticipationStatusBadge
      :status="participant.inviteStatus"
    >
    </EventParticipationStatusBadge>

    <v-chip
      :color="types[participant.participantType].color"
      outlined
    >
      <v-avatar
        v-if="participant.participantType !== 'user'"
      >
        <v-icon>
          {{ types[participant.participantType].icon }}
        </v-icon>
      </v-avatar>
      <user-avatar
        v-else
        :user="participant.user"
        :size="'24'"
        :side="'left'"
        :color="types[participant.participantType].color"
      ></user-avatar>

      {{ getName(participant) }}
    </v-chip>
  </div>
</template>

<script>
import { mdiBriefcase, mdiAccount } from '@mdi/js'
import UserAvatar from '@/components/UserAvatar.vue'
import EventParticipationStatusBadge from '@/components/EventParticipationStatusBadge.vue'

export default {
  components: {
    UserAvatar,
    EventParticipationStatusBadge,
  },
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: {
      mdiBriefcase,
      mdiAccount,
    },
    types: {
      bc: {
        color: '#009688',
        icon: mdiBriefcase,
      },
      user: {
        color: 'primary',
        icon: mdiAccount,
      },
      contact: {
        color: '#8BC34A',
        icon: mdiAccount,
      },
    },

  }),
  methods: {
    matchColor() {

    },
    getName(participant) {
      switch (participant.participantType) {
        case 'bc':
          return participant.businessContact.name
        case 'contact':
          return `${participant.contact.firstName} ${participant.contact.lastName}`
        case 'user':
          return participant.user.displayName

        default:
          return 'Nom'
      }
    },
  },

}
</script>

<style>

</style>
