<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title
        class="d-flex justify-space-between pb-3"
        primary-title
      >
        <h4>
          Apprenants
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiAccountMultiple }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiAccountMultiple } from '@mdi/js'

export default {
  components: {
  },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: true,
    orderedEvents: [],
    icons: {
      mdiAccountMultiple,
    },
  }),
  watch: {
    contacts(val) {
      if (val) {
        this.loading = false
      }
    },
  },
  methods: {
  },
}
</script>

<style>

</style>
