<template>
  <div>
    <v-autocomplete
      v-model="model"
      :loading="loading"
      :search-input.sync="search"
      :items="invites"
      hide-selected
      hide-details
      solo
      placeholder="Ajouter des invités"
      no-filter
      append-icon=""
      @input="updateInviteList"
    >
      <template v-slot:item="{ item }">
        <person-chip
          :person="item"
        ></person-chip>
      </template>
    </v-autocomplete>
    <v-list v-if="selection.length > 0">
      <v-list-item
        v-for="(item,index) in selection"

        :key="index"
        class="d-flex justify-space-between"
      >
        <person-chip :person="item"></person-chip>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="primary"
          @click="removeSelection(item._id)"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import PersonChip from '@/components/PersonChip.vue'

export default {
  components: {
    PersonChip,
  },
  data: () => ({
    search: '',
    model: null,
    contacts: [],
    invites: [],
    loading: false,
    selection: [],
    icons: {
      mdiClose,
    },
  }),
  computed: {

  },
  watch: {
    search(val) {
      if (val) {
        if (val.length > 0) {
          this.inviteList(val)
        }
      } else {
        this.invites = []
      }
    },
    selection(val) {
      if (val) {
        this.$emit('invitesUpdate', val)
      }
    },
  },
  methods: {
    inviteList(query) {
      this.loading = true
      this.fetchContacts(query)
      const companyUsers = this.$store.state.userlist
      let filteredUsers = []
      if (this.search) {
        if (this.search.length !== 0) {
          const lowSearch = this.search.toLowerCase()
          filteredUsers = companyUsers.filter(user => Object.values(user).some(val => String(val).toLowerCase().includes(lowSearch)))
        }
      }

      const inviteList = filteredUsers.map(v => ({ ...v, inviteType: 'user' }))
      const contactsTyped = this.contacts.map(v => ({ ...v, inviteType: 'contact' }))
      const finalArray = inviteList.concat(contactsTyped)

      this.invites = finalArray.filter(x => !this.selection.some(e => e._id === x._id))
      this.loading = false
    },
    fetchContacts(query) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/contacts/find-index/${this.$store.state.company._id}/?q=${query}`)
        .then(res => {
          this.contacts = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
    updateInviteList(e) {
      if (e) {
        this.selection.push(e)
        this.invites = []
        this.model = ''
      }
    },
    removeSelection(payload) {
      const foundIndex = this.selection.findIndex(obj => obj._id === payload)
      if (foundIndex > -1) {
        this.selection.splice(foundIndex, 1)
      }
    },
  },
}
</script>

<style>

</style>
