<template>
  <v-card class="mt-4">
    <v-card-title
      class="d-flex justify-space-between"
      primary-title
    >
      <h4>
        Fichiers et documents
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiBriefcase }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      Composant de listage de fichiers
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="primary"
        @click="generateFileDialog = true"
      >
        Générer un document
      </v-btn>
      <v-btn
        text
        color="primary"
      >
        Ajouter un fichier
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="generateFileDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          Génération de document
        </v-card-title>
        <v-divider>
        </v-divider>
        <div v-if="templates.length > 0">
          <v-card-subtitle class="mt-2">
            <p>Vos modèles :</p>
          </v-card-subtitle>
          <v-card-text>
            <v-autocomplete
              clearable
              :items="templates"
              item-text="title"
              item-value="_id"
            >
            </v-autocomplete>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-subtitle class="mt-2">
            <p>Vous n'avez pas encore de modèles</p>
          </v-card-subtitle>
        </div>
        <v-card-actions>
          <v-btn
            color="success"
            @click="$router.push({name:'templates-new'})"
          >
            Nouveau modèle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  data() {
    return {
      generateFileDialog: false,
      icons: {
        mdiBriefcase,
      },
      templates: [],
    }
  },
  mounted() {
    this.fetchTemplates()
  },
  methods: {
    fetchTemplates() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/template/`)
        .then(res => {
          this.templates = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style>

</style>
