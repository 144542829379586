<template>
  <div>
    <v-row>
      <v-col
        md="4"
        sm="12"
      >
        <business-contact-infos-card
          v-if="businessContact"
          :bc="businessContact"
        ></business-contact-infos-card>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        md="4"
        sm="12"
      >
        <business-contact-managers-card
          v-if="businessContact"
          :managers="businessContact.managers"
        ></business-contact-managers-card>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
        <business-contact-files-card
          v-if="businessContact"
          :bc="businessContact"
        ></business-contact-files-card>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        md="4"
        sm="12"
      >
        <business-contact-events-card
          v-if="businessContact"
          :bc="businessContact"
        ></business-contact-events-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="businessContact.contacts"
        md="4"
        sm="12"
      >
        <business-contact-contacts-card
          :contacts="businessContact.contacts"
        ></business-contact-contacts-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BusinessContactInfosCard from './BusinessContactInfosCard.vue'
import BusinessContactManagersCard from './BusinessContactManagersCard.vue'
import BusinessContactFilesCard from './BusinessContactFilesCard.vue'
import BusinessContactEventsCard from './BusinessContactEventsCard.vue'
import BusinessContactContactsCard from './BusinessContactContactsCard.vue'

export default {
  metaInfo: {
    title: 'Fiche entreprise',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    BusinessContactInfosCard,
    BusinessContactManagersCard,
    BusinessContactFilesCard,
    BusinessContactEventsCard,
    BusinessContactContactsCard,
  },
  data: () => ({
    businessContact: {},
    contacts: [],
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchBusinessContact()])
        .then(values => {
          [this.businessContact] = values
        })
        .catch(err => {
          console.log(err)
          if (err.response.status === 403) {
            this.$store.commit('auth_error')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async fetchBusinessContact() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/business-contact/find/${this.$route.params.id}`)

      return response.data
    },
  },
}
</script>

<style>

</style>
