<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title
        class="d-flex justify-space-between pb-3"
        primary-title
      >
        <h4>
          Rendez-vous
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiCalendar }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="events.length > 0">
        <events-list
          :events="events"
        ></events-list>
      </v-card-text>
      <v-card-text
        v-else
        class="d-flex
        justify-center"
      >
        Pas d'évènements
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="primary"
          block
          @click="eventDialog = true"
        >
          Nouvel évènement
        </v-btn>
      </v-card-actions>
    </v-card>
    <NewEventDialog
      :business-contact="bc"
      :dialog="eventDialog"
      @closeDialog="eventDialog = false"
      @newEvent="(payload) => events.push(payload)"
    >
    </NewEventDialog>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import EventsList from '@/components/EventsList.vue'
import NewEventDialog from '@/components/NewEventDialog.vue'

export default {
  components: {
    EventsList,
    NewEventDialog,
  },
  props: {
    bc: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    eventDialog: false,
    loading: true,
    events: [],
    orderedEvents: [],
    icons: {
      mdiCalendar,
    },
  }),
  watch: {
    bc(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchEvents()])
        .then(values => {
          [this.events] = values
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchEvents() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/event/${this.$store.state.company._id}/?businessContact=${this.bc._id}`)

      return res.data
    },
  },
}
</script>

<style>

</style>
