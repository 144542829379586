<template>
  <div>
    <v-skeleton-loader
      v-if="managers.length <= 0"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title
        class="d-flex justify-space-between pb-3"
        primary-title
      >
        <h4>
          {{ managers.length > 1 ? `Gérants (${managers.length})` : 'Gérant' }}
        </h4>
        <v-icon
          top
          right
        >
          {{ managers.length > 1 ? icons.mdiAccountGroup : icons.mdiAccount }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-sheet>
          <v-chip-group
            v-model="selectedManager"
            mandatory
            active-class="primary--text"
          >
            <v-chip
              v-for="(manager, index) in managers"
              :key="index"
            >
              {{ manager.firstName }} {{ manager.lastName }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-card-text>
      <v-card-text>
        <p>
          <b>
            Status :
          </b>
          {{ managers[selectedManager].managerStatus }}
        </p>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            cols="8"
          >
            <v-text-field
              v-model="managers[selectedManager].phone"
              label="Téléphone"
              outlined
              readonly
              dense
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center"
          >
            <v-btn
              :href="`tel:${managers[selectedManager].phone}`"
              icon
              outlined
              color="primary"
            >
              <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center"
          >
            <v-btn
              icon
              color="primary"
              outlined
              @click="copyClip(managers[selectedManager].phone)"
            >
              <v-icon>{{ icons.mdiClipboard }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="managers[selectedManager].email"
              label="E-mail"
              readonly
              dense
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center"
          >
            <v-btn
              :href="`tel:${managers[selectedManager].phone}`"
              icon
              outlined
              color="primary"
            >
              <v-icon>{{ icons.mdiEmail }}</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center"
          >
            <v-btn
              icon
              color="primary"
              outlined
              @click="copyClip(managers[selectedManager].phone)"
            >
              <v-icon>{{ icons.mdiClipboard }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          outlined
          block
        >
          Voir le profil apprenant
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mdiBriefcase,
  mdiPhoneOutline,
  mdiClipboard,
  mdiEmail,
  mdiAccount,
  mdiAccountGroup,
} from '@mdi/js'

export default {
  props: {
    managers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedManager: 0,
    icons: {
      mdiBriefcase,
      mdiPhoneOutline,
      mdiClipboard,
      mdiEmail,
      mdiAccount,
      mdiAccountGroup,
    },
  }),
  methods: {
    copyClip(e) {
      navigator.clipboard.writeText(e)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'info',
            text: 'Copié dans le presse-papier',
            value: true,
          })

          return true
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la copie',
            value: true,
          })
          console.log(err)
        })

      return false
    },
  },

}
</script>

<style>

</style>
