<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title
        class="d-flex justify-space-between pb-3"
        primary-title
      >
        <h4>
          {{ bc.name }}
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle class="text-h5">
        Adresse
      </v-card-subtitle>
      <v-card-text>
        <p>
          {{ bc.address.line1 }}
        </p>
        <p v-if="bc.address.line2">
          {{ bc.address.line2 }}
        </p>
        <p>
          {{ bc.address.postcode }} {{ bc.address.city }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle class="text-h5">
        Infos légales
      </v-card-subtitle>
      <v-card-text>
        <p>
          <b>Siret : </b>{{ bc.siret }}
        </p>
        <p v-if="bc.businessStarted">
          <b>Création :</b> {{ new Date(bc.businessStarted) | date('dd/MM/yyyy') }} ({{ getYears(bc.businessStarted) }} ans)
        </p>
        <p>
          <b>Code NAF : </b> {{ bc.nafCode }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle class="text-h5">
        Opco
      </v-card-subtitle>
      <v-card-text>
        <p>
          <b>Nom : </b>{{ bc.opcoInfos.operateurdecompetence }}
        </p>
        <p>
          <b>Convention :</b> {{ bc.opcoInfos.libelle }}
        </p>
        <p>
          <b>INTAPE :</b> {{ bc.opcoInfos.intape }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  props: {
    bc: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: {
      mdiBriefcase,
    },
  }),
  computed: {
    loading() {
      if (this.bc) {
        return false
      }

      return true
    },
  },
  methods: {
    getYears(date) {
      const dateCreate = new Date(date)
      const yearDifMs = Date.now() - dateCreate
      const YearDate = new Date(yearDifMs) // miliseconds from epoch

      return Math.abs(YearDate.getUTCFullYear() - 1970)
    },
  },

}
</script>

<style>

</style>
