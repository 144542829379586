<template>
  <v-card>
    <div class="d-flex justify-end">
      <v-btn
        icon
        color="primary"
      >
        <v-icon>{{ icons.mdiPencil }}</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
      >
        <v-icon>{{ icons.mdiTrashCan }}</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        @click="$emit('closeMenu')"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </div>
    <v-card-text>
      <v-row
        align="center"
        class="spacer"
      >
        <v-col
          cols="2"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
        </v-col>
        <v-col cols="10">
          <p class="text-h5">
            {{ event.name }}
          </p>
          <p>
            {{ new Date(event.start) | date('EEEEEEE dd LLLLLL') }}
          </p>
          <p>
            {{ new Date(event.start) | date('HH:mm') }} - {{ new Date(event.end) | date('HH:mm') }}
          </p>
        </v-col>
      </v-row>
      <v-row
        v-if="event.type === 'visio' && event.link"
        align="center"
        class="spacer"
      >
        <v-col
          cols="2"
        >
          <v-icon>{{ icons.mdiMessageVideo }}</v-icon>
        </v-col>
        <v-col cols="10">
          <a
            :href="event.link"
            target="_blank"
          >
            <p
              v-text="event.link"
            ></p>
          </a>
        </v-col>
      </v-row>
      <v-row
        v-if="event.description"
        align="center"
        class="spacer"
      >
        <v-col
          cols="2"
        >
          <v-icon>{{ icons.mdiInformation }}</v-icon>
        </v-col>
        <v-col cols="10">
          <p
            max-width="400"
            v-text="event.description"
          ></p>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="spacer"
      >
        <v-col
          cols="2"
        >
          <v-icon>{{ icons.mdiAccount }}</v-icon>
        </v-col>
        <v-col
          cols="10"
        >
          <event-participant-chip
            v-for="(participant, index) in participants"
            :key="index"
            :participant="participant"
          ></event-participant-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import EventParticipantChip from '@/components/EventParticipantChip.vue'
import {
  mdiTrashCan, mdiPencil, mdiClose, mdiCalendar, mdiAccount, mdiInformation, mdiMessageVideo,
} from '@mdi/js'

export default {
  components: {
    EventParticipantChip,
  },
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: {
      mdiTrashCan,
      mdiPencil,
      mdiClose,
      mdiCalendar,
      mdiAccount,
      mdiInformation,
      mdiMessageVideo,
    },
  }),
  computed: {
    participants() {
      const participants = []
      this.event.users.forEach(user => {
        user.participantType = 'user'
        participants.push(user)
      })
      this.event.contacts.forEach(contact => {
        contact.participantType = 'contact'
        participants.push(contact)
      })
      this.event.businessContacts.forEach(bc => {
        bc.participantType = 'bc'
        participants.push(bc)
      })

      return participants
    },
  },

}
</script>

<style>

</style>
