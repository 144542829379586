<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :overlay="false"
    max-width="550px"
    transition="dialog-transition"
    @click:outside="$emit('closeDialog')"
  >
    <v-card>
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Nouvel évènement
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiCalendar }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row no-gutters>
            <v-col
              cols="11"
              offset="1"
            >
              <v-text-field
                v-model="event.name"
                :rules="[v => !!v || 'Le nom est requis']"
                name="eventName"
                placeholder="Ajouter un titre"
              ></v-text-field>
            </v-col>
            <v-col
              offset="1"
              cols="11"
            >
              <v-btn-toggle
                v-model="event.type"
                tile
                color="primary"
                group
              >
                <v-btn
                  v-for="(type, index) in eventTypes"
                  :key="index"
                  text
                  :value="type.value"
                >
                  {{ type.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-icon>{{ icons.mdiClock }}</v-icon>
            </v-col>
            <v-col
              cols="4"
            >
              <date-picker-menu @dateSelected="selectDate"></date-picker-menu>
            </v-col>
            <v-col
              cols="5"
            >
              <timeslot-picker
                @startChange="(payload) => startTimeSlot = payload"
                @endChange="(payload) => endTimeSlot = payload"
              >
              </timeslot-picker>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row
            v-if="event.type ==='visio'"
            align="center"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-icon>{{ icons.mdiMessageVideo }}</v-icon>
            </v-col>
            <v-col
              cols="10"
            >
              <v-text-field
                v-model="event.link"
                name="Visio link"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="mt-3"
            align="center"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-icon>{{ icons.mdiAccountMultiple }}</v-icon>
            </v-col>
            <v-col
              cols="10"
            >
              <v-list>
                <v-list-item
                  v-if="!inviteArea"
                  @click="inviteArea= true"
                >
                  Ajouter des invités
                </v-list-item>
                <event-invite-field
                  v-if="inviteArea"
                  @invitesUpdate="allocateInvites"
                >
                </event-invite-field>
              </v-list>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-icon>{{ icons.mdiBriefcase }}</v-icon>
            </v-col>
            <v-col
              cols="10"
            >
              <v-list>
                <v-list-item>
                  <person-chip :person="bc"></person-chip>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-icon>{{ icons.mdiText }}</v-icon>
            </v-col>
            <v-col
              cols="10"
            >
              <v-list>
                <v-list-item
                  v-if="!descArea"
                  :ripple="false"
                  @click="descArea= true"
                >
                  Ajouter une description
                </v-list-item>
                <v-list-item v-if="descArea">
                  <v-textarea
                    v-model="event.description"
                    placeholder="Ajouter une description"
                    rows="3"
                  >
                  </v-textarea>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn
          text
          color="warning"
          @click="$emit('closeDialog')"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="createEvent"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiCalendar, mdiClock, mdiAccountMultiple, mdiMessageVideo, mdiText, mdiBriefcase,
} from '@mdi/js'
import PersonChip from '@/components/PersonChip.vue'
import EventInviteField from '@/components/EventInviteField.vue'
import DatePickerMenu from '@/components/DatePickerMenu.vue'
import TimeslotPicker from '@/components/TimeslotPicker.vue'

export default {
  components: {
    PersonChip,
    DatePickerMenu,
    TimeslotPicker,
    EventInviteField,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    businessContact: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    bc: {},
    eventTypes: [
      {
        name: 'Evenement',
        type: 'standard',
      },
      {
        name: 'Visio',
        type: 'standard',
      },
      {
        name: 'Rappel',
        type: 'reminder',
      },
      {
        name: 'Autre',
        type: 'other',
      },

    ],
    event: {
      contacts: [],
      company: '',
      users: [],
      businessContacts: [],
      name: '',
      type: 'standard',
      start: new Date(),
      end: new Date(),
      link: '',
    },
    startTimeSlot: '11:00',
    endTimeSlot: '12:00',
    date: new Date(),
    descArea: false,
    inviteArea: false,
    valid: true,
    selectedBc: false,
    icons: {
      mdiCalendar,
      mdiClock,
      mdiAccountMultiple,
      mdiMessageVideo,
      mdiText,
      mdiBriefcase,
    },
  }),
  watch: {
    businessContact(val) {
      if (val) {
        this.bc = val
        this.bc.inviteType = 'bc'
      }
    },
    endTimeSlot(val) {
      if (val) {
        this.event.end = this.buildDate(val)
      }
    },
    startTimeSlot(val) {
      if (val) {
        this.event.start = this.buildDate(val)
      }
    },
  },
  mounted() {
    this.bc = this.businessContact
    this.bc.inviteType = 'bc'
  },
  methods: {
    reset() {
      this.event = {
        contacts: [],
        company: '',
        users: [],
        businessContacts: [],
        name: '',
        type: 'standard',
        start: new Date(),
        end: new Date(),
        link: '',
      }
      this.startTimeSlot = '11:00'
      this.endTimeSlot = '12:00'
    },
    createEvent() {
      if (this.$refs.form.validate()) {
        this.event.company = this.$store.state.company._id
        this.event.businessContacts = [{ businessContact: this.bc._id }]
        this.$http.post(`${process.env.VUE_APP_API_URL}/event/new`, this.event)
          .then(res => {
            if (res.status === 200) {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Nouvel évènement créé',
                value: true,
              })
              this.reset()
              this.$emit('newEvent', res.data)
              this.$emit('closeDialog')
            }
          })
          .catch(err => console.log(err))
          .finally()
      }
    },
    allocateInvites(payload) {
      this.event.users = []
      this.event.contacts = []
      payload.forEach(invite => {
        switch (invite.inviteType) {
          case 'user':
            this.event.users.push({ user: invite._id })
            break
          case 'contact':
            this.event.contacts.push({ contact: invite._id })
            break
          default:
            break
        }
      })
    },
    selectDate(payload) {
      this.date = new Date(payload)
      this.event.start = this.buildDate(this.startTimeSlot)
      this.event.end = this.buildDate(this.endTimeSlot)
    },
    buildDate(timeslot) {
      const splitTimeSlot = timeslot.split(':')
      const date = new Date(this.date)
      date.setHours(splitTimeSlot[0])
      date.setMinutes(splitTimeSlot[1])

      return date
    },
  },

}
</script>

<style>

</style>
