<template>
  <div
    class="d-flex justify-center align-center"
  >
    <v-autocomplete
      v-model="start"
      append-icon=""
      :items="intervals"
      @change="changeEndtime"
    ></v-autocomplete>
    <v-autocomplete
      v-model="end"
      append-icon=""
      :items="intervalsFiltered"
      item-value="interval"
      :item-text="(val)=> `${val.interval} (${val.timeDif})`"
      auto-select-first
    >
      <template
        #[`selection`]="{item}"
      >
        {{ item.interval }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  data: () => ({
    start: '11:00',
    end: '12:00',
  }),
  computed: {
    intervals() {
      const intervals = []
      const date = new Date()
      const format = {
        hour: 'numeric',
        minute: 'numeric',
      }

      for (let minutes = 0; minutes < 24 * 60; minutes += 15) {
        date.setHours(0)
        date.setMinutes(minutes)
        intervals.push(date.toLocaleTimeString('fr', format))
      }

      return intervals
    },
    intervalsFiltered() {
      let index = this.intervals.findIndex(x => x === this.start)
      index += 1
      const futurIntervals = this.intervals.slice(index)
      futurIntervals.forEach((inter, i) => {
        const timeDif = this.timeDif(i)
        futurIntervals[i] = {
          interval: inter,
          timeDif,
        }
      })

      return futurIntervals
    },
  },
  watch: {
    start(val) {
      this.$emit('startChange', val)
    },
    end(val) {
      this.$emit('endChange', val)
    },
  },
  methods: {
    timeDif(index) {
      const minutes = (index + 1) * 15
      if (minutes < 46) {
        return `${minutes} mins`
      }
      const hours = Math.floor(minutes / 60)
      const minutesRest = minutes % 60

      return minutesRest > 0 ? `${hours}h${minutesRest}` : `${hours}h`
    },
    changeEndtime(e) {
      const index = this.intervals.findIndex(x => x === e)

      this.end = this.intervals[index + 4]
    },
  },

}
</script>

<style scoped>

</style>
