<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="participationStatus[status].color"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ participationStatus[status].icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>  {{ participationStatus[status].text }}</span>
  </v-tooltip>
</template>

<script>
import { mdiCloseCircleOutline, mdiCheckboxMarkedCircleOutline, mdiHelpCircleOutline } from '@mdi/js'

export default {
  props: {
    status: {
      type: String,
      default: 'waiting',
    },
  },
  data: () => ({
    participationStatus: {
      waiting: {
        icon: mdiHelpCircleOutline,
        color: '#FFB74D',
        text: 'En attente',
      },
      confirmed: {
        icon: mdiCheckboxMarkedCircleOutline,
        color: 'success',
        text: 'Confirmé',
      },
      refused: {
        icon: mdiCloseCircleOutline,
        color: 'error',
        text: 'Refusé',
      },

    },
    icons: {
      mdiCheckboxMarkedCircleOutline,
      mdiCloseCircleOutline,
      mdiHelpCircleOutline,
    },
  }),

}
</script>

<style>

</style>
