<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        locale="fr"
        no-title
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: false,
    dateFormatted: new Date(),
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

  }),
  watch: {
    date(val) {
      if (val) {
        this.dateFormatted = this.formatDate(val)
        this.$emit('dateSelected', this.date)
      }
    },
  },
  mounted() {
    this.dateFormatted = this.formatDate(this.date)
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const dateJs = new Date(date)

      return this.$date(dateJs, 'EEEEEEEE, dd LLLLLLL')
    },
  },
}
</script>

<style>

</style>
