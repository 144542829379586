<template>
  <div class="mh-350">
    <div
      v-for="(dayEvents, i) in orderedEvents"
      :key="i"
    >
      <div
        class="d-flex align-center justify-space-between"
      >
        <v-avatar>
          <span
            class="text-h5"
            :class="dayEvents.timeline ==='today' ? 'primary--text': ''"
          >{{ dayEvents.fullDate | date('dd') }}</span>
        </v-avatar>

        <p
          class="text-h5"
          :class="dayEvents.timeline ==='today' ? 'primary--text': ''"
        >
          {{ dayEvents.fullDate | date('LLLLLL') }}
        </p>

        <span :class="dayEvents.timeline ==='today' ? 'primary--text': ''">
          ({{ dayEvents.fullDate | date('EEEEEEE') }})
        </span>
      </div>

      <v-list
        dense
        rounded
      >
        <v-list-item
          v-for="(event, index) in dayEvents.events"
          :key="index"
          :class="dayEvents.timeline ==='past' ? 'text--disabled' : ''"
          @click="show($event, event)"
        >
          <v-list-item-icon>
            <v-icon>{{ event.type === 'visio' ? icons.mdiMessageVideo : icons.mdiMapMarker }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="event.name"></v-list-item-title>

            <v-list-item-subtitle :class="dayEvents.timeline ==='past' ? 'text--disabled' : ''">
              {{ new Date(event.start) | date('HH:mm') }} à {{ new Date(event.end) | date('HH:mm') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider v-if="i < orderedEvents.length -1 "></v-divider>
    </div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <event-infos-card
        :event="selectedEvent"
        @closeMenu="showMenu = false"
      ></event-infos-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiMessageVideo, mdiMapMarker } from '@mdi/js'
import EventInfosCard from '@/components/EventInfosCard.vue'

export default {
  components: {
    EventInfosCard,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
    orderedEvents: [],
    selectedEvent: {},
    icons: {
      mdiMessageVideo,
      mdiMapMarker,
    },
  }),
  watch: {
    events(val) {
      if (val) {
        this.orderEvents()
      }
    },
  },
  mounted() {
    this.orderEvents()
  },
  methods: {
    show(e, thisEvent) {
      e.preventDefault()
      this.selectedEvent = thisEvent
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    orderEvents() {
      this.orderedEvents = []
      this.events.forEach(event => {
        const dateStart = new Date(event.start)
        const foundIndex = this.orderedEvents.findIndex(x => this.sameDay(x.fullDate, dateStart))
        if (foundIndex !== -1) {
          this.orderedEvents[foundIndex].events.push(event)
        } else {
          this.orderedEvents.push({
            timeline: this.timeline(dateStart),
            fullDate: dateStart,
            events: [event],
          })
        }
      })
      this.orderedEvents.sort((a, b) => new Date(a.fullDate) - new Date(b.fullDate))
    },
    sameDay(d1, d2) {
      return d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate()
    },
    timeline(date) {
      const now = new Date()
      const eventDate = new Date(date)
      if (this.sameDay(eventDate, now)) {
        return 'today'
      } if (eventDate < now) {
        return 'past'
      }

      return 'future'
    },
  },

}
</script>

<style>
.mh-350{
  max-height: 350px;
  overflow-y: scroll;
}
</style>
